<template>
	<div>
		<div class="match_item content flex">
			<img src="../../../assets/image/img/match.png" class="match_image">
			<div class="match_content">
				<div class="match_name">
					<div style="width: 800px" class="one-line">
						{{ info.name }}
					</div>
				</div>
				<div class="flex-space-between" style="align-items: flex-end">
					<div class="contentM">
						<div class="content_text">
							<img src="../../../assets/image/icon/dianhua.png" class="icon_s">{{ info.phone }}
						</div>
						<div class="content_text">
							<img src="../../../assets/image/icon/dingwei.png"
									 class="icon_s">{{ info.province + info.city + info.area + info.address }}
						</div>
						<div class="content_text">
							<img src="../../../assets/image/icon/shijian.png" class="icon_s">{{ info.work_time }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="rich_body content">
			<div class="sign_body_S2 flex-direction-column">
				<div class="sign_header flex" style="margin: 0 20px">
					<div class="line_shu2"></div>
					场馆相册
				</div>
				<div class="img_body">
					<div v-for="(item,index) in info.album">
						<el-image
							class="img_xiangce"
							:src="item.src"
							fit="cover"
							:preview-src-list="info.albumCopy">
						</el-image>
					</div>
					<Lack
						v-if="info.album.length==0"
						text="暂无内容"
						minHeight="260px"
						imgWidth="102px"
						imgHeight="68px"
						:imgSrc="require('../../../assets/image/img/15.png')"
					></Lack>
				</div>

			</div>
		</div>

		<div class="rich_body content">
			<div class="sign_body_S flex-direction-column">
				<div class="sign_header flex">
					<div class="line_shu2"></div>
					场馆介绍
				</div>
				<div class="rich" style="display: inline-block;margin-top: 20px" v-html="info.detail"></div>
				<Lack
					v-if="!info.detail"
					text="暂无内容"
					minHeight="260px"
					imgWidth="102px"
					imgHeight="68px"
					:imgSrc="require('../../../assets/image/img/15.png')"
				></Lack>
			</div>
		</div>


	</div>
</template>

<script>
import {getSiteInfo, getSiteList} from "@/service/api";
import Lack from "@/components/lack/lack.vue";

export default {
	name: "matchSignUp",
	components: {Lack},
	data() {
		return {
			info: {
				name: '',
				phone: '',
				work_time: '',
				province: '',
				area: '',
				city: '',
				address: '',
				album: [],
				albumCopy: [],
				detail: ''
			},
		}
	},
	mounted() {
		let info = {}
		if (this.$route.params.info) {
			sessionStorage.setItem('params', this.$route.params.info)
			info = JSON.parse(this.$route.params.info)
		} else {
			info = JSON.parse(sessionStorage.getItem('params'))
		}
		this.getData(info.id)
	},
	methods: {
		getData(id) {
			getSiteInfo({
				id
			}).then((res) => {
				this.info = res.msg
				this.info.albumCopy = res.msg.album.map(item => {
					return item.src
				})
			})
		},
	}
}
</script>

<style scoped lang="less">
.match_item {
	height: 230px;
	background: #FFFFFF;

	.match_image {
		width: 288px;
		height: 180px;
		margin-left: 25px;
	}

	.match_content {
		position: relative;
		display: flex;
		flex-direction: column;
		height: 170px;
		margin-left: 18px;

		.match_name {
			width: 980px;
			font-size: 22px;
			margin-left: 18px;
			color: #000000;
			line-height: 28px;
		}

		.contentM {
			margin-left: 20px;
			margin-top: 40px;

			.content_text {
				height: 35px;
				font-size: 16px;
				font-weight: 400;
				line-height: 35px;
				color: #000000;

				display: flex;
				align-items: center;

				.icon_s {
					width: 16px;
					height: 16px;
					margin-right: 10px;
				}
			}
		}

		.img_icon {
			width: 20px;
			height: 20px;
		}

		.line_shu {
			width: 1.5px;
			height: 12px;
			margin: 0 10px;
			background-color: #535353;
		}

		.img_txt {
			font-size: 14px;
			color: #535353;
			line-height: 16px;
			margin-left: 4px;
		}

		.match_btn {
			position: absolute;
			right: 20px;
			width: 92px;
			height: 32px;
			background: #B4375F;
			border-radius: 5px 5px 5px 5px;
			font-size: 14px;
			color: #FFFFFF;
			margin-right: 17px;
		}
	}

	&:nth-child(2n) {
		margin-right: 0;
	}
}

.rich_body {
	background: #FFFFFF;
	margin-top: 20px;

	.sign_body_S {
		padding: 20px;

		.sign_header {
			width: 100%;
			border-bottom: 1px solid #E8E8E8;
			padding-bottom: 20px;
			font-size: 20px;
			color: #212F82;
			line-height: 23px;

			.line_shu2 {
				width: 4px;
				height: 20px;
				background: #212F82;
				margin-right: 14px;
			}
		}
	}

	.sign_body_S2 {
		padding: 20px 0;

		.sign_header {
			border-bottom: 1px solid #E8E8E8;
			padding-bottom: 20px;
			font-size: 20px;
			color: #212F82;
			line-height: 23px;

			.line_shu2 {
				width: 4px;
				height: 20px;
				background: #212F82;
				margin-right: 14px;
			}
		}
	}

	.img_body {
		display: flex;
		align-items: center;
		overflow-x: auto;
		overflow-y: hidden;
		padding: 30px 0 30px 20px;

		.img_xiangce {
			width: 188px;
			height: 117px;
			margin-right: 20px;
			flex-shrink: 0;

		}
	}
}

/deep/ .el-dialog__header {
	padding: 0;
}

/deep/ .el-dialog__body {
	padding: 0;
}

.sign_image {
	background-image: url('../../../assets/image/img/signDia.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 558px;
	height: 634px;
	position: relative;

	.sing_text {
		font-weight: bold;
		font-size: 48px;
		color: #212F82;
		line-height: 56px;
		margin-top: 66px;
	}

	.sing_text2 {
		font-weight: 400;
		font-size: 26px;
		color: #333333;
		line-height: 30px;
		margin-top: 20px;
		margin-bottom: 70px;
	}

	.er_png {
		width: 360px;
		height: 360px;
		border-radius: 10px;
	}

	.close_png {
		position: absolute;
		top: 38px;
		right: 34px;
		width: 34px;
		height: 34px;
	}
}

/deep/ .el-dialog {
	background-color: transparent !important; //弹出层透明
	box-shadow: none !important; /* 可能还需要移除阴影 */
}

/* 隐藏滚动条 */
::-webkit-scrollbar {
	display: flex;
	width: 10px;
}

::-webkit-scrollbar { /*滚动条基本样式，高度*/
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-thumb { /*滚动条上层颜色*/
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	//background: rgba(0,0,0,0.2);
	background-color: #212F82;
}

::-webkit-scrollbar-track { /*滚动条底层颜色*/
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 0;
	background: rgba(0, 0, 0, 0.1);
}
</style>
